body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  li {
    float: left;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
  li a:hover {
    background-color: #111;
  }
  
  /* .userName {
    float: right;
    color: white;
    margin-right: 10px;
    height: 80%;
  }
  
  .userProfilePicture {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    height: 80%;
  } */
:root {
  --primary-color: rgb(11, 96, 232);
  --border-radius: 5px;
  --black-color: #351897;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  background-color: rgb(229, 229, 229);
  position: relative;
  display: grid;
  grid-template-columns: 300px 600px;
  border-radius: --border-radius;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.356);
  display: inline-block;
  height: 550px;
  width: 300px;
  padding-bottom: 100px;
  margin: 10px;
}

/* .containerWithoutImage {
    position: relative;
} */

img {
  width: 300px;
  height: 300px;
  border-radius: --border-radius 0 0 --border-radius;
  /* object-fit: contain; */
  /* border: 1px solid green; */
}

h2 {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #818189;
}

/* p {
    color: --black-color;
    font-weight: bold;
    font-size: 1.2rem;
} */

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Tooltip text */
.dot .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgb(60, 60, 60);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dot:hover .tooltiptext {
  visibility: visible;
}

.natureIcon:hover .tooltiptext {
  visibility: visible;
}

.natureIcon .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgb(60, 60, 60);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.CardTopBar {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row-reverse;
  grid-gap: 3px;
  gap: 3px;
  margin-top: 2px;
}

.cardTopBarItems {
  order: 1;
}

.data-containers {
  display: flex;
  /* margin: 20px 0 10px; */
  /* border: 1px solid red; */
  width: 100%;
  padding: 5px;
  margin: 5px;
}

.description-text {
  height: 80px;
  overflow-y: auto;
  margin: 5px;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.individual-data-container {
  /* margin-right: 40px; */
  bottom: 0px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.wine-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
}

.top-menu-button {
  padding: 0;
  border: none;
  background: none;
  margin: 5px;
}

.cardContainer {
    display: flex; /* or inline-flex */
    flex-wrap: wrap;
    flex-shrink: 3;
  }
input[type=text] {
    padding:10px;
    margin:10px 0; 
  }

  input[type=number] {
    /* padding:10px; */
    margin:10px 0;
    -webkit-appearance: textfield;
            appearance: textfield;
    /* margin: 0;  */
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      /* margin: 0;  */
}

  select {
    /* width: 100%; */
    padding:10px;
    border-radius:10px;
  }

  /* input[type=submit] { */

    /* remove default behavior */
    /* appearance:none;
    -webkit-appearance:none; */
  
    /* usual styles */
    /* padding:10px;
    border:none;
    background-color:#3F51B5;
    color:#fff;
    font-weight:600;
    border-radius:5px; */
    /* width:100%; */
  
  /* } */


  textarea {
    resize: none;
    height: 100px;
  }


