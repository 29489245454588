:root {
  --primary-color: rgb(11, 96, 232);
  --border-radius: 5px;
  --black-color: #351897;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  background-color: rgb(229, 229, 229);
  position: relative;
  display: grid;
  grid-template-columns: 300px 600px;
  border-radius: --border-radius;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.356);
  display: inline-block;
  height: 550px;
  width: 300px;
  padding-bottom: 100px;
  margin: 10px;
}

/* .containerWithoutImage {
    position: relative;
} */

img {
  width: 300px;
  height: 300px;
  border-radius: --border-radius 0 0 --border-radius;
  /* object-fit: contain; */
  /* border: 1px solid green; */
}

h2 {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #818189;
}

/* p {
    color: --black-color;
    font-weight: bold;
    font-size: 1.2rem;
} */

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Tooltip text */
.dot .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgb(60, 60, 60);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dot:hover .tooltiptext {
  visibility: visible;
}

.natureIcon:hover .tooltiptext {
  visibility: visible;
}

.natureIcon .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgb(60, 60, 60);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.CardTopBar {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row-reverse;
  gap: 3px;
  margin-top: 2px;
}

.cardTopBarItems {
  order: 1;
}

.data-containers {
  display: flex;
  /* margin: 20px 0 10px; */
  /* border: 1px solid red; */
  width: 100%;
  padding: 5px;
  margin: 5px;
}

.description-text {
  height: 80px;
  overflow-y: auto;
  margin: 5px;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.individual-data-container {
  /* margin-right: 40px; */
  bottom: 0px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.wine-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
}

.top-menu-button {
  padding: 0;
  border: none;
  background: none;
  margin: 5px;
}
