input[type=text] {
    padding:10px;
    margin:10px 0; 
  }

  input[type=number] {
    /* padding:10px; */
    margin:10px 0; 
    -moz-appearance: textfield;
    appearance: textfield;
    /* margin: 0;  */
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      /* margin: 0;  */
}

  select {
    /* width: 100%; */
    padding:10px;
    border-radius:10px;
  }

  /* input[type=submit] { */

    /* remove default behavior */
    /* appearance:none;
    -webkit-appearance:none; */
  
    /* usual styles */
    /* padding:10px;
    border:none;
    background-color:#3F51B5;
    color:#fff;
    font-weight:600;
    border-radius:5px; */
    /* width:100%; */
  
  /* } */


  textarea {
    resize: none;
    height: 100px;
  }

