ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  li {
    float: left;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
  li a:hover {
    background-color: #111;
  }
  
  /* .userName {
    float: right;
    color: white;
    margin-right: 10px;
    height: 80%;
  }
  
  .userProfilePicture {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    height: 80%;
  } */